<template>
  <v-form lazy-validation v-model="valid" ref="form">
    <v-row>
      <v-col cols="12" sm="12" md="11" lg="11">
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Fixed Asset Type<span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-select
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="fixedAssetCategory"
                  item-text="type"
                  return-object
                  :items="dropDownCategory"
                  @change="onChangeType"
                  :rules="[v => !!v || 'Type is required']"
                ></v-select>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Fixed Asset Code</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="form.code"
                  disabled
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Fixed Asset Description<span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-textarea
                  outlined
                  flat
                  dense
                  rows="3"
                  background-color="white"
                  v-model="form.description"
                  :rules="[v => !!v || 'Description is required']"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Usage Date<span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-menu
                  v-model="menuDateUsage"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      flat
                      dense
                      background-color="white"
                      v-model="form.usageDate"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="form.usageDate"
                    @input="menuDateUsage = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Purchase Date<span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-menu
                  v-model="menuDatePurchase"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      flat
                      dense
                      background-color="white"
                      v-model="form.purchaseDate"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="form.purchaseDate"
                    @input="menuDatePurchase = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Asset Cost<span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model.number="form.assetCost"
                  :rules="[v => v > 0 || 'Asset Cost must more than 0']"
                  suffix="IDR"
                  type="number"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Total Depreciation</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model.number="form.totalDepreciation"
                  suffix="IDR"
                  type="number"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Quantity<span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model.number="form.quantity"
                  :rules="[v => v > 0 || 'Quantity must more than 0']"
                  type="number"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Fiscal Correction</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model.number="form.fiscalCorrection"
                  type="number"
                  suffix="%"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="12" md="1" lg="1" class="py-0">
        <v-row class="py-0">
          <v-col cols="12" class="mb-4 py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="primary" v-on="on" @click="submit">
                  <v-icon large>
                    mdi-content-save
                  </v-icon>
                </v-btn>
              </template>
              <span>Save</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";

export default {
  name: "create-fixed-asset",
  data: () => ({
    defaultForm: {
      formNumber: "",
      purchaseDate: moment().format("yyyy-MM-DD"),
      usageDate: moment().format("yyyy-MM-DD"),
      lastDepreciationDate: null,
      fixedAssetCategoryId: null,
      code: "",
      description: "",
      assetCost: 0,
      totalDepreciation: 0,
      quantity: 0,
      fiscalCorrection: 0,
      active: true,
    },
    menuDatePurchase: false,
    menuDateUsage: false,
    form: {},
    fixedAssetCategory: null,
    valid: true,
  }),

  computed: {
    ...mapState("fixedAsset", ["lastFormNumber"]),
    ...mapState("fixedAssetCategory", ["dropDownCategory"]),
  },

  watch: {},

  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        this.form.formNumber = this.lastFormNumber;
        this.form.lastDepreciationDate = moment(this.form.purchaseDate)
          .add(this.fixedAssetCategory.estimatedLife, "years")
          .endOf("month")
          .format("yyyy-MM-DD");
        if (
          !moment().isBetween(
            this.form.purchaseDate,
            this.form.lastDepreciationDate,
            undefined,
            "[]"
          )
        ) {
          this.form.active = false;
        }
        this.$store.dispatch("fixedAsset/create", this.form);
        this.clear();
      } else {
        this.$store.dispatch("toast", {
          type: "error",
          message: "All Form is required",
        });
      }
    },
    clear() {
      this.form = Object.assign({}, this.defaultForm);
    },
    onChangeType(val) {
      this.form.fixedAssetCategoryId = val.id;
      this.form.code = val.code;
    },
  },

  mounted() {
    this.$store.dispatch("fixedAssetCategory/getListCategory");
    this.$store.dispatch("fixedAsset/getLastFormNumber");
    this.form = Object.assign({}, this.defaultForm);
  },
};
</script>

<style></style>
